// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const getUser = async () => {
  try {
    const url = `${endpoints.more.root}/user`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during more:', error);
    throw error;
  }
};

const createComplaint = async (data) => {
  try {
    const url = `${endpoints.more.root}/comment`;

    data.type = 'help';
    const response = await axiosFeaturesInstance.post(url, data);
    return response;
  } catch (error) {
    console.error('Error during create complaint:', error);
    throw error;
  }
};

const deleteAccount = async () => {
  try {
    const url = `${endpoints.more.root}/configurations/delete-account`;
    const response = await axiosFeaturesInstance.delete(url);
    return response;
  } catch (error) {
    console.error('Error during delete account:', error);
    throw error;
  }
};

const createCVU = async () => {
  try {
    const url = `${endpoints.more.root}/cvu`;
    const response = await axiosFeaturesInstance.post(url, {});
    return response;
  } catch (error) {
    console.error('Error during delete account:', error);
    throw error;
  }
}

const changePhone = async (emailOtp, phone) => {
  try {
    const fingerprint = localStorage.getItem('fingerprint');
    const url = `${endpoints.more.root}/change-phone`;
    const data = { emailOtp, phone, fingerprint };
    const response = await axiosFeaturesInstance.patch(url, data);
    return response;
  } catch (error) {
    console.error('Error during phone change:', error);
    throw error;
  }
};

export const more = {
  getUser,
  needHelp: {
    createComplaint,
  },
  configurations: {
    deleteAccount,
  },
  createCVU,
  changePhone,
};
