import PropTypes from 'prop-types';
import React from 'react';
// mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// components
import Label from 'src/commons/components/label';
import Avatar from 'src/commons/components/avatar';
import Iconify from 'src/commons/components/iconify';
// utils
import * as cuitHelper from '@frani/cuit';
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

const SuboperationItem = ({ suboperation, operation }) => {
  const theme = useTheme();
  const { title, amount, senderUserId, receiverUserId, senderWalletId } = suboperation;

  const sender = {
    name: senderUserId?.name,
    lastname: senderUserId?.lastname,
    avatar: senderUserId?.avatar,
    cuit: cuitHelper.format(senderUserId?.cuit),
  };

  const receiver = {
    name: receiverUserId?.name,
    lastname: receiverUserId?.lastname,
    avatar: receiverUserId?.avatar,
    cuit: cuitHelper.format(receiverUserId?.cuit),
  };

  if (
    operation &&
    operation?.params &&
    operation?.params?.bill &&
    operation?.params?.bill?.description
  ) {
    receiver.name = operation?.params?.bill.description;
    receiver.lastname = '';
    receiver.cuit = '';
  }

  return (
    <Stack
      sx={{
        width: 1,
        p: 2,
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
      direction="column"
    >
      <Typography
        variant="h4"
        fontWeight="fontWeightSemiBold"
        fontSize={16}
        width="1"
        flexGrow={1}
        sx={{ color: 'grey.850', textTransform: 'capitalize' }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        fontWeight="fontWeightRegular"
        sx={{ color: 'grey.550', textTransform: 'capitalize' }}
      >
        Billetera {senderWalletId?.currencyId?.alternativeName}
      </Typography>

      <Stack
        sx={{
          width: 1,
          my: 1,
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Avatar alt={`${sender?.name} ${sender?.lastname}`} img={sender?.avatar || ''} />
        <IconButton disabled sx={{ display: 'flex', flexDirection: 'row' }}>
          <Label variant="soft" color="info" sx={{ borderRadius: '50px', p: 2 }}>
            <Typography variant="h5">{fCurrency({ number: amount, withSymbol: true })}</Typography>
          </Label>
          <Iconify icon="solar:arrow-right-line-duotone" width={36} sx={{ color: 'info.dark' }} />
        </IconButton>
        <Avatar alt={`${receiver?.name} ${receiver?.lastname}`} img={receiver?.avatar || ''} />
      </Stack>
      <Stack flexGrow={1} direction="column" alignItems="flex-start">
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.900' }}
        >
          <strong>De:</strong> {`${sender?.name} ${sender?.lastname}`} · {sender?.cuit}
        </Typography>
      </Stack>
      <Stack flexGrow={1} direction="column" alignItems="flex-start" pt={1}>
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.900' }}
        >
          <strong>A:</strong> {`${receiver?.name} ${receiver?.lastname}`} · {receiver?.cuit}
        </Typography>
      </Stack>
    </Stack>
  );
};

SuboperationItem.propTypes = {
  suboperation: PropTypes.object,
  operation: PropTypes.object,
};

export default SuboperationItem;
