import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/commons/components/hook-form';
import HeaderSubtitle from 'src/commons/components/header-subtitle/header-subtitle';
// services
import { services } from 'src/services/api';
// utils
import { currencies } from '../utils/currencies';
import { getWalletProperties } from '../utils/helpers';

// ----------------------------------------------------------------------

export default function WalletForm() {
  const router = useRouter();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const NewWalletSchema = Yup.object().shape({
    name: Yup.string()
      .max(16, 'El nombre no puede tener más de 16 caracteres')
      .required('El nombre es requerido'),
    currency: Yup.string().required('Email is required'),
    // not required
    mendozaActivaTicket: Yup.string().test('only-numbers', 'Ingrese solo números', (value) => {
      if (!value) return true; // Allow empty value
      return /^[0-9]+$/.test(value);
    }),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      currency: 'Pesos',
      mendozaActivaTicket: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewWalletSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const selectedCurrency = currencies.find((currency) => currency.label === data.currency);
      const formDataWithCurrencyValue = { ...data, currency: selectedCurrency?.value };
      const res = await services.features.wallets.create(formDataWithCurrencyValue);
      if (res.success) {
        reset();
        enqueueSnackbar('Billetera creada con éxito');
        router.push(paths.home.wallets.successfullyCreated);
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
      <Stack direction="column" justifyContent="space-between" sx={{ height: 1 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <HeaderSubtitle
              alignCenter
              subtitle="Configura los detalles de tu nueva billetera para comenzar a recibir y administrar tus fondos"
            />
          </Grid>

          <Grid xs={12} md={8}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                required
                name="name"
                label="Nombre"
                helperText={<Typography variant="caption">Máximo 16 caracteres</Typography>}
              />

              <RHFAutocomplete
                required
                name="currency"
                label="Tipo de moneda"
                options={currencies.map((currency) => currency.label)}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => {
                  const selectedCurrency = currencies.find((currency) => currency.label === option);
                  const { backgroundColor } = getWalletProperties(theme, selectedCurrency.label);
                  if (!selectedCurrency) {
                    return null;
                  }

                  return (
                    <li {...props} key={selectedCurrency.label}>
                      <Box
                        key={selectedCurrency.label}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 18,
                          height: 18,
                          mr: 2,
                          borderRadius: '50%',
                          backgroundColor,
                        }}
                      >
                        {selectedCurrency.icon}
                      </Box>
                      {selectedCurrency.label}
                    </li>
                  );
                }}
              />

              <RHFTextField name="mendozaActivaTicket" label="Mendoza Activa Ticket" />
              <Typography variant="caption" color="textSecondary" sx={{ marginTop: -2 }}>
                *Completar este campo solo si es beneficiario del programa Mendoza Activa.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Stack sx={{ pb: 2 }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            loading={isSubmitting}
          >
            Confirmar
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
