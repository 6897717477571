import PropTypes from 'prop-types';
import _ from 'lodash';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency } from 'src/utils/format-number';
// hooks
import { useLocalStorage } from 'src/commons/hooks/use-local-storage';
// components
import { RouterLink } from 'src/routes/components';
import Label from 'src/commons/components/label';
import Iconify from 'src/commons/components/iconify';
// routes
import { paths } from 'src/routes/paths';
// helpers
import { getWalletProperties } from '../utils/helpers';

// ----------------------------------------------------------------------

function WalletItem({ wallet, customSx }) {
  const { amount, currency, _id } = wallet;
  const theme = useTheme();
  const [hideBalance, setHideBalance] = useLocalStorage('hide-balance', true);

  const { backgroundColor, icon: walletIcon } = getWalletProperties(
    theme,
    currency?.alternativeName
  );

  const isActivaCredit = backgroundColor === '#8463EA';

  return (
    <Stack
      justifyContent="center"
      sx={{
        height: 240,
        textAlign: 'left',
        // width: 1,
        px: 3,
        // my: 2,
        borderRadius: 1.5,
        background: backgroundColor,
        ...customSx,
      }}
    >
      <div>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <Box
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 1.5,
              display: 'flex',
              backgroundColor: 'common.white',
              mb: 2,
              width: 40,
              height: 40,
            }}
          >
            <Box
              component="img"
              alignItems="center"
              src={walletIcon}
              justifyContent="center"
              sx={{
                width: 32,
                height: 32,
              }}
            />
          </Box>
          <Stack direction="row" alignItems="center">
            <IconButton
              href={paths.home.wallets.details(_id)}
              component={RouterLink}
              sx={{
                p: 0.25,
                borderRadius: 1.5,
                width: 32,
                height: 32,
              }}
            >
              <Iconify width={32} sx={{ color: 'common.black' }} icon="ic:round-chevron-right" />
            </IconButton>
          </Stack>
        </Stack>
        <Typography sx={{ typography: 'h4', color: 'common.black', textTransform: 'capitalize' }}>
          {wallet?.name}
        </Typography>
        <Stack direction="row" alignItems="flex-start">
          <Typography
            sx={{
              typography: 'subtitle2',
              color: `${isActivaCredit ? 'common.white' : 'common.black'}`,
            }}
          >
            {`Billetera en ${_.capitalize(currency?.alternativeName)}`}
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => setHideBalance(!hideBalance)}
            sx={{ opacity: 0.48, p: 0.25 }}
          >
            <Iconify
              icon={hideBalance ? 'solar:eye-closed-bold' : 'solar:eye-bold'}
              sx={{ color: `${isActivaCredit ? 'common.white' : 'common.black'}` }}
            />
          </IconButton>
        </Stack>
        <Typography
          variant="h3"
          fontSize={32}
          sx={{ color: `${isActivaCredit ? 'common.white' : 'common.black'}` }}
        >
          {hideBalance ? '$******.**' : fCurrency({ number: amount, withSymbol: true })}
        </Typography>
        <Stack>
          <Label sx={{ mt: 1, width: '35%' }} variant="filled" color="default">
            {wallet?.isHidden ? 'Oculta' : 'Disponible'}
          </Label>
          {wallet?.isBeneficiary && (
            <Label sx={{ mt: 1, width: '35%' }} variant="filled" color="default">
              Beneficiario MA
            </Label>
          )}
        </Stack>
      </div>
    </Stack>
  );
}

WalletItem.propTypes = {
  wallet: PropTypes.object,
  customSx: PropTypes.object,
};

export default WalletItem;
