import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const StepContext = createContext({
  activeStepId: '',
  setActiveStepId: () => {},
  activeStepNumber: 0,
  handleNext: () => {},
  handleBack: () => {},
});

export const StepRegisterProvider = ({ children }) => {
  const [activeStepId, setActiveStepId] = useState('accountsInfo');
  const [activeStepNumber, setActiveStepNumber] = useState(0);

  const navigate = useNavigate();

  const handleStepChange = (stepIndexChange) => {
    const currentStepIndex = allowedSteps.indexOf(activeStepId);
    const nextStepIndex = currentStepIndex + stepIndexChange;

    if (nextStepIndex >= 0 && nextStepIndex < allowedSteps.length) {
      const nextStepId = allowedSteps[nextStepIndex];
      setActiveStepId(nextStepId);
      // Update localStorage with the current step
      localStorage.setItem('activeStepId', nextStepId);
      localStorage.setItem('activeStepNumber', nextStepIndex);
      setActiveStepNumber(nextStepIndex);
    }
  };

  const handleNext = () => {
    const selectedAccount = localStorage.getItem('onboardingSelectedAccount').toLowerCase();
    const storedCuit = localStorage.getItem('cuit');
    if (activeStepId === 'successfullyTakenVideoselfie') {
      const lowercaseSelectedAccount = selectedAccount.toLowerCase();

      if (lowercaseSelectedAccount === 'cuenta comercio' && storedCuit[0] === '3') {
        setActiveStepId('businessInfo');
        localStorage.setItem('activeStepId', 'businessInfo');
        localStorage.setItem('activeStepNumber', allowedSteps.indexOf('businessInfo'));
        setActiveStepNumber(allowedSteps.indexOf('businessInfo'));
      } else if (lowercaseSelectedAccount === 'cuenta comercio' && storedCuit[0] === '2') {
        setActiveStepId('uploadMyPymeCertificateInfo');
        localStorage.setItem('activeStepId', 'uploadMyPymeCertificateInfo');
      } else {
        setActiveStepId('welcomeToPalta');
        localStorage.setItem('activeStepId', 'welcomeToPalta');
      }
    } else if (activeStepId === 'emailSent') {
      handleStepChange(2);
    } else if (activeStepId === 'consultCuit' && selectedAccount === 'cuenta personal') {
      handleStepChange(3);
    } else {
      handleStepChange(1);
    }
  };

  const handleBack = () => {
    const selectedAccount = localStorage.getItem('onboardingSelectedAccount').toLowerCase();
    if (activeStepNumber === 0) navigate('/');
    if (activeStepId === 'phoneInput' && selectedAccount === 'cuenta personal') {
      handleStepChange(-3);
    } else if (activeStepId === 'emailOtp') {
      handleStepChange(-2);
    } else {
      handleStepChange(-1);
    }
  };

  const value = useMemo(
    () => ({
      activeStepId,
      setActiveStepId,
      activeStepNumber,
      handleNext,
      handleBack,
      allowedSteps,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStepId, activeStepNumber]
  );

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

StepRegisterProvider.propTypes = {
  children: PropTypes.node,
};

export default StepContext;

const allowedSteps = [
  'accountsInfo',
  'consultCuit',
  'fantasyName',
  'address',
  'phoneInput',
  'phoneOtp',
  'emailInfo',
  'emailInput',
  'emailSent',
  'resendEmailCode',
  'emailOtp',
  'successValidateEmail',
  'userRegulations',
  'dniInfo',
  'nationalIdFront',
  'nationalIdBack',
  'successfullyTakenPhotos',
  'videoselfieInfo',
  'videoselfie',
  'successfullyTakenVideoselfie',
  'businessInfo',
  // 'confirmBusinessData',
  'isProxy',
  'uploadBusinessDocumentsInfo',
  'uploadBusinessDocuments',
  'uploadBusinessPowerInfo',
  'uploadBusinessPower',
  'uploadMyPymeCertificateInfo',
  'uploadMyPymeCertificate',
  'welcomeToPalta',
];
