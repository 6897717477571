import axios from 'axios';
import axiosFeaturesInstance, { endpoints } from './axios';

const getOnboarding = async (onboardingId) => {
  try {
    const fingerprint = localStorage.getItem('fingerprint');
    const response = await axiosFeaturesInstance(`${endpoints.onboarding.create}/${onboardingId}`, {
      params: { fingerprint },
    });
    return response;
  } catch (error) {
    console.error('Error during create onboarding:', error);
    throw error;
  }
};

const create = async (type, cuit, isCommerce) => {
  try {
    const fingerprint = localStorage.getItem('fingerprint');
    const response = await axiosFeaturesInstance.post(endpoints.onboarding.create, {
      fingerprint,
      type,
      cuit,
      isCommerce,
    });
    return response;
  } catch (error) {
    console.error('Error during create onboarding:', error);
    throw error;
  }
};

const updateStatus = async (step, contact, code) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');
      const requestBody = {
        fingerprint: localStorage.getItem('fingerprint'),
        [step === 'phone' ? 'phone' : 'email']: contact,
      };

      if (code) {
        requestBody.code = code;
      }

      const response = await axiosFeaturesInstance.patch(
        `${endpoints.onboarding.create}/${cleanOnboardingId}/${step}`,
        requestBody
      );

      return response;
    }

    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error('Error during update status onboarding:', error);
    throw error;
  }
};

const updateFantasyName = async (fantasyName) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');
      const requestBody = {
        fingerprint: localStorage.getItem('fingerprint'),
        fantasyName,
      };

      const response = await axiosFeaturesInstance.patch(
        `${endpoints.onboarding.create}/${cleanOnboardingId}/fantasy-name`,
        requestBody
      );

      return response;
    }

    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error('Error during update status onboarding:', error);
    throw error;
  }
};

const updateIsProxy = async (isProxy) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');
      const requestBody = {
        fingerprint: localStorage.getItem('fingerprint'),
        isProxy,
      };

      const response = await axiosFeaturesInstance.patch(
        `${endpoints.onboarding.create}/${cleanOnboardingId}/is-proxy`,
        requestBody
      );

      return response;
    }

    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error('Error during update status onboarding:', error);
    throw error;
  }
};

const updateAddress = async (address) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');
      const requestBody = {
        fingerprint: localStorage.getItem('fingerprint'),
        address,
      };

      const response = await axiosFeaturesInstance.patch(
        `${endpoints.onboarding.create}/${cleanOnboardingId}/address`,
        requestBody
      );

      return response;
    }

    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error('Error during update status onboarding:', error);
    throw error;
  }
};

const updateStepStatus = async (step, value) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');
      const requestBody = {
        fingerprint: localStorage.getItem('fingerprint'),
        [step]: { status: value },
      };

      const response = await axiosFeaturesInstance.patch(
        `${endpoints.onboarding.create}/${cleanOnboardingId}`,
        requestBody
      );

      return response;
    }
    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error(`Error during updating step [${step}] status [${value}]`, error);
    throw error;
  }
};

const signStepUrl = async (step) => {
  try {
    const onboardingId = localStorage.getItem('onboardingId');
    if (onboardingId) {
      const cleanOnboardingId = onboardingId.replace(/"/g, '');

      let queryParams = { fingerprint: localStorage.getItem('fingerprint'), step };

      if (step === 'documentation') {
        queryParams = { ...queryParams, documentationExt: 'pdf' };
      }

      if (step === 'legalPower') {
        queryParams = { ...queryParams, documentationExt: 'pdf' };
      }

      if (step === 'myPymeCertificate') {
        queryParams = { ...queryParams, documentationExt: 'pdf' };
      }

      const response = await axiosFeaturesInstance(
        `${endpoints.onboarding.create}/${cleanOnboardingId}/sign-url`,
        { params: queryParams }
      );

      return response;
    }

    return { message: 'Onboarding ID not provided.' };
  } catch (error) {
    console.error(`Error during request sign url for step [${step}]`, error);
    throw error;
  }
};

const uploadFileToS3 = async (signedUrl, fileSrc, type) => {
  try {
    let formattedFile = fileSrc;
    if (type === 'photo') {
      const base64Response = await fetch(fileSrc);
      const blob = await base64Response.blob();

      const arrayBuffer = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(blob);
      });
      formattedFile = arrayBuffer;
    }

    const response = await axios.put(signedUrl, formattedFile, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    return false;
  }
};

const getBusinessInfoFromCuit = async (cuit) => {
  try {
    const response = await axios(`https://afip-api.palta.app/cuits/${cuit}`);
    return response;
  } catch (error) {
    console.error(`Error during request for cuit [${cuit}]`, error);
    throw error;
  }
};

const updateBcraRegulations = async (data) => {
  try {
    const { activity, isPep, isFacta, isOcde, isObligatedSubject, civilStatus } = data;
    const fingerprint = localStorage.getItem('fingerprint');
    const onboardingId = localStorage.getItem('onboardingId');
    const response = await axiosFeaturesInstance.patch(
      `${endpoints.onboarding.create}/${onboardingId}/bcra`,
      {
        fingerprint,
        activity,
        isPep,
        isFacta,
        isOcde,
        isObligatedSubject,
        civilStatus,
      }
    );
    return response;
  } catch (error) {
    console.error('Error during create onboarding:', error);
    throw error;
  }
};

export const onboarding = {
  getOnboarding,
  create,
  updateStatus,
  updateStepStatus,
  signStepUrl,
  uploadFileToS3,
  getBusinessInfoFromCuit,
  updateBcraRegulations,
  updateFantasyName,
  updateIsProxy,
  updateAddress,
};
