import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency } from 'src/utils/format-number';
// assets
import { noHistory } from 'src/features/history/assets/illustrations';
// components
import Iconify from 'src/commons/components/iconify';
import MovementItem from 'src/features/home/components/movements/movement-item';
import MovementSkeleton from 'src/features/home/components/movements/movement-skeleton';
import NoMovements from 'src/features/history/components/movements/no-movements';
import WalletDetailsSkeleton from './wallet-details-skeleton';

// ----------------------------------------------------------------------

function WalletDetailsView({
  wallet,
  movements,
  loadingMovements,
  loadingWallet,
  icon,
  walletBackgroundColor,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const handleSetSearchQuery = (value) => {
    setSearchQuery(value);
  };

  const filteredMovements = movements.filter((movement) => {
    const movementDescription = `${movement?.title} ${movement?.amount} ${movement?.currency} ${movement?.subtitle}`;
    return movementDescription.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const handleWithdrawMoney = () => {
    navigate('/send-money', { state: { currency: { code: wallet?.currency?.code } } });
  };
  const handlePay = () => {
    navigate('/qr-payment');
  };

  return (
    <Stack
      justifyContent="space-between"
      direction="column"
      spacing={3}
      sx={{
        pt: 2,
        width: 1,
        height: filteredMovements?.length === 0 ? 'auto' : '100%',
        flex: 1,
      }}
    >
      {loadingWallet ? (
        <WalletDetailsSkeleton />
      ) : (
        <Stack direction="column" spacing={1} alignItems="center">
          <Box
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 1.5,
              display: 'flex',
              backgroundColor: walletBackgroundColor,
              mb: 1,
              width: 56,
              height: 56,
            }}
          >
            <Box
              component="img"
              alignItems="center"
              src={icon}
              justifyContent="center"
              sx={{
                width: 32,
                height: 32,
              }}
            />
          </Box>
          <Typography
            fontSize={18}
            fontWeight="fontWeightMedium"
            sx={{ color: 'grey.700', textTransform: 'capitalize' }}
          >
            {wallet?.currency?.alternativeName}
          </Typography>
          <Typography variant="h3" fontSize={32} sx={{ color: 'common.black' }}>
            {fCurrency({ number: wallet?.amount, withSymbol: true })}
          </Typography>
          <Stack direction="row" spacing={1.5} sx={{ width: 1 }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="medium"
              sx={{ fontSize: '14px', fontWeight: 'fontWeightBold' }}
              startIcon={<Iconify icon="majesticons:arrow-down" />}
              onClick={handleWithdrawMoney}
            >
              Retirar
            </Button>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              sx={{ fontSize: '14px', fontWeight: 'fontWeightBold' }}
              startIcon={<Iconify icon="bi:qr-code-scan" />}
              onClick={handlePay}
            >
              Pagar
            </Button>
          </Stack>
        </Stack>
      )}
      {!loadingMovements && movements.length === 0 && (
        <NoMovements
          image={noHistory}
          altImage="No Operations"
          title="No hay movimientos"
          description={`No tienes movimientos registrados hasta el momento en tu Billetera ${wallet?.currency?.alternativeName}`}
        />
      )}
      {loadingMovements &&
        Array.from({ length: 6 }).map((_, index) => <MovementSkeleton key={index} />)}

      {!loadingMovements && movements.length > 0 && (
        <Stack direction="column" alignItems="flex-start">
          <Typography fontWeight={700} variant="subtitle1" sx={{ color: 'green.dark' }}>
            Movimientos
          </Typography>
          <TextField
            value={searchQuery}
            onChange={(event) => handleSetSearchQuery(event.target.value)}
            placeholder="Buscar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'green.primary' }} />
                </InputAdornment>
              ),
            }}
            sx={{ width: { xs: 1, sm: 260 }, my: 2 }}
          />
          {filteredMovements?.map((movement) => (
            <MovementItem key={movement?.id} movement={movement} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

WalletDetailsView.propTypes = {
  wallet: PropTypes.object,
  movements: PropTypes.array,
  loadingWallet: PropTypes.bool,
  loadingMovements: PropTypes.bool,
  icon: PropTypes.string,
  walletBackgroundColor: PropTypes.string,
};

export default WalletDetailsView;
