import PropTypes from 'prop-types';
import React from 'react';
// mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// components
import Label from 'src/commons/components/label';
import Avatar from 'src/commons/components/avatar';
import Iconify from 'src/commons/components/iconify';
// utils
import * as cuitHelper from '@frani/cuit';
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

// {
//                     "receiverUserId": {
//                         "_id": "64d38c2e47fe9ae26751d43f",
//                         "name": "demo",
//                         "lastname": "demo",
//                         "cuit": "20123456786",
//                         "emails": [
//                             "d**o@palta.app"
//                         ],
//                         "business": {},
//                         "phone": "+5492******000"
//                     },
//                     "amount": 87654321,
//                     "counterparty": {
//                         "id": "30716324814",
//                         "id_type": "CUIT_CUIL",
//                         "name": "palta sas"
//                     },
//                     "status": "completed",
//                     "title": "Recibiste dinero",
//                     "description": "a palta sas"
//                 }
const SuboperationItem = ({ suboperation }) => {
  const theme = useTheme();
  const { title, amount, receiverUserId, counterparty } = suboperation;

  const sender = {
    name: counterparty?.name || '',
    avatar: '',
    cuit: counterparty?.id,
  };
  if (counterparty?.id_type === 'CUIT_CUIL') sender.cuit = cuitHelper.format(counterparty?.id);

  const receiver = {
    name: receiverUserId?.name,
    lastname: receiverUserId?.lastname,
    avatar: receiverUserId?.avatar,
    cuit: cuitHelper.format(receiverUserId?.cuit),
  };

  return (
    <Stack
      sx={{
        width: 1,
        p: 2,
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
      direction="column"
    >
      <Typography
        variant="h4"
        fontWeight="fontWeightSemiBold"
        fontSize={16}
        width="1"
        flexGrow={1}
        sx={{ color: 'grey.850', textTransform: 'capitalize' }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        fontWeight="fontWeightRegular"
        sx={{ color: 'grey.550', textTransform: 'capitalize' }}
      >
        Billetera {receiverUserId?.currencyId?.alternativeName}
      </Typography>

      <Stack
        sx={{
          width: 1,
          my: 1,
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Avatar alt={`${sender?.name}`} img={sender?.avatar || ''} />
        <IconButton disabled sx={{ display: 'flex', flexDirection: 'row' }}>
          <Label variant="soft" color="info" sx={{ borderRadius: '50px', p: 2 }}>
            <Typography variant="h5">{fCurrency({ number: amount, withSymbol: true })}</Typography>
          </Label>
          <Iconify icon="solar:arrow-right-line-duotone" width={36} sx={{ color: 'info.dark' }} />
        </IconButton>
        <Avatar alt={`${receiver?.name} ${receiver?.lastname}`} img={receiver?.avatar || ''} />
      </Stack>
      <Stack flexGrow={1} direction="column" alignItems="flex-start">
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.900' }}
        >
          <strong>De:</strong> {`${sender?.name} `} · {sender?.cuit}
        </Typography>
      </Stack>
      <Stack flexGrow={1} direction="column" alignItems="flex-start" pt={1}>
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.900' }}
        >
          <strong>A:</strong> {`${receiver?.name} ${receiver?.lastname}`} · {receiver?.cuit}
        </Typography>
      </Stack>
    </Stack>
  );
};

SuboperationItem.propTypes = {
  suboperation: PropTypes.object,
};

export default SuboperationItem;
