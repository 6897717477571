import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useMemo, useCallback, useState } from 'react';
//
import { FormContext } from './form-context';

// ----------------------------------------------------------------------

const defaultValues = {
  receiver: null,
  storeName: null,
  motive: '',
  senderWallet: null,
  receiverWallet: null,
  checkoutId: null,
  amount: null,
  operationRes: null,
};

// ----------------------------------------------------------------------

export function FormProvider({ children }) {
  const [formData, setFormData] = useState(defaultValues);

  const onUpdate = useCallback(
    (name, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setFormData]
  );

  // Reset
  const onReset = useCallback(() => {
    setFormData(defaultValues);
  }, [setFormData]);

  const canReset = !isEqual(formData, defaultValues);

  const memoizedValue = useMemo(
    () => ({
      ...formData,
      onUpdate,
      // Reset
      canReset,
      onReset,
    }),
    [onReset, onUpdate, formData, canReset]
  );

  return <FormContext.Provider value={memoizedValue}>{children}</FormContext.Provider>;
}

FormProvider.propTypes = {
  children: PropTypes.node,
};
